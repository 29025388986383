import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { Calculator } from "./components/Calculator/Calculator";
import { phrasesClient, phrasesSales } from "./const";


function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="/sales" replace />
      
    },
    {
      path: "/sales",
      element: <Calculator title="Калькулятор. Расчет тарифов для малого бизнеса" phrases={phrasesSales} />,
      errorElement: <h1>404</h1>
    },
    {
      path: "/client",
      element: <Calculator isClientCalc title="Калькулятор. Расчет переподписки для клиентского сервиса" phrases={phrasesClient} />,
      errorElement: <h1>404</h1>
    }
  ])
  return <RouterProvider router={router} />
}

export default App;
