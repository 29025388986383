export const Logo = () => (
  <svg
    width="200"
    height="41"
    viewBox="0 0 200 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5809_24102)">
      <path
        d="M131.898 19.1128L130.743 16.3495H124.664L123.51 19.1128H122.355L127.05 7.67578H128.281L132.975 19.1128H131.898ZM127.742 8.7504L125.049 15.4284H130.436L127.742 8.7504Z"
        fill="#DA0E17"
      />
      <path
        d="M134.205 14.9679C134.205 12.5116 135.821 10.6694 138.284 10.6694C139.746 10.6694 140.592 11.2067 141.208 12.1279L140.592 12.6652C139.977 11.8208 139.284 11.5138 138.284 11.5138C136.36 11.5138 135.129 13.049 135.129 15.0447C135.129 17.0404 136.283 18.5756 138.284 18.5756C139.207 18.5756 139.977 18.2686 140.592 17.4242L141.208 17.9615C140.515 18.8059 139.746 19.4199 138.284 19.4199C135.821 19.3432 134.205 17.3474 134.205 14.9679Z"
        fill="#DA0E17"
      />
      <path
        d="M142.67 14.9679C142.67 12.5116 144.286 10.6694 146.748 10.6694C148.211 10.6694 149.057 11.2067 149.673 12.1279L149.057 12.6652C148.441 11.8208 147.749 11.5138 146.748 11.5138C144.825 11.5138 143.593 13.049 143.593 15.0447C143.593 17.0404 144.748 18.5756 146.748 18.5756C147.672 18.5756 148.441 18.2686 149.057 17.4242L149.673 17.9615C148.98 18.8059 148.211 19.4199 146.748 19.4199C144.286 19.3432 142.67 17.3474 142.67 14.9679Z"
        fill="#DA0E17"
      />
      <path
        d="M151.674 19.1129V10.823H152.52V17.7313L157.291 10.823H158.138V19.1129H157.291V12.1279L152.52 19.1129H151.674Z"
        fill="#DA0E17"
      />
      <path
        d="M160.447 14.9679C160.447 12.5116 162.063 10.6694 164.526 10.6694C165.988 10.6694 166.834 11.2067 167.45 12.1279L166.834 12.6652C166.219 11.8208 165.526 11.5138 164.526 11.5138C162.602 11.5138 161.371 13.049 161.371 15.0447C161.371 17.0404 162.525 18.5756 164.526 18.5756C165.449 18.5756 166.219 18.2686 166.834 17.4242L167.45 17.9615C166.757 18.8059 165.988 19.4199 164.526 19.4199C161.986 19.3432 160.447 17.3474 160.447 14.9679Z"
        fill="#DA0E17"
      />
      <path
        d="M170.913 19.1129V11.5906H168.527V10.823H174.222V11.5906H171.836V19.1129H170.913Z"
        fill="#DA0E17"
      />
      <path
        d="M175.605 14.9679C175.605 12.5884 177.298 10.6694 179.607 10.6694C182.146 10.6694 183.532 12.6652 183.532 15.0447V15.275H176.606C176.683 17.0404 177.914 18.5756 179.838 18.5756C180.915 18.5756 181.839 18.1918 182.531 17.4242L182.993 17.9615C182.146 18.8059 181.146 19.2664 179.761 19.2664C177.375 19.3432 175.605 17.501 175.605 14.9679ZM179.607 11.3603C177.683 11.3603 176.683 13.049 176.606 14.5074H182.685C182.608 13.1257 181.685 11.3603 179.607 11.3603Z"
        fill="#DA0E17"
      />
      <path
        d="M185.84 19.1129V10.823H186.686V14.4307H191.457V10.823H192.304V19.1129H191.457V15.1982H186.686V19.1129H185.84Z"
        fill="#DA0E17"
      />
      <path
        d="M196.615 19.1129V11.5906H194.152V10.823H200.001V11.5906H197.538V19.1129H196.615Z"
        fill="#DA0E17"
      />
      <path
        d="M52.4824 19.1898V7.90625H60.4855V9.97874H54.9449V19.1898H52.4824Z"
        fill="#DA0E17"
      />
      <path
        d="M60.793 17.5778C61.4855 17.5778 61.8703 16.887 62.1012 14.8913L62.6398 10.9766H69.2578V19.1898H67.1031V12.8955H64.5637L64.2558 15.1983C63.948 17.9616 62.9476 19.3433 60.793 19.3433V17.5778Z"
        fill="#DA0E17"
      />
      <path
        d="M76.2597 19.1897V18.3454C75.7211 19.0362 74.7207 19.42 73.7203 19.42C72.4121 19.42 70.873 18.5756 70.873 16.7334C70.873 14.8145 72.4121 14.1236 73.7203 14.1236C74.7976 14.1236 75.798 14.4307 76.2597 15.1215V14.0469C76.2597 13.2025 75.5672 12.6652 74.4898 12.6652C73.6433 12.6652 72.7969 12.9722 72.1043 13.5863L71.2578 12.1279C72.2582 11.2836 73.5664 10.823 74.7976 10.823C76.6445 10.823 78.3375 11.5906 78.3375 13.8933V19.1897H76.2597ZM76.2597 17.194V16.1961C75.9519 15.7356 75.1824 15.5053 74.5668 15.5053C73.7203 15.5053 73.0277 15.9658 73.0277 16.7334C73.0277 17.501 73.7203 17.8848 74.5668 17.8848C75.2594 17.8848 75.9519 17.6545 76.2597 17.194Z"
        fill="#DA0E17"
      />
      <path
        d="M80.5703 19.1898V10.9766H85.7261C87.4191 10.9766 88.2656 11.9744 88.2656 13.049C88.2656 14.0469 87.65 14.661 86.8804 14.8913C87.8039 15.0448 88.4195 15.8891 88.4195 16.887C88.4195 18.1151 87.573 19.113 85.88 19.113H80.5703V19.1898ZM86.034 13.5096C86.034 13.049 85.7261 12.8188 85.2644 12.8188H82.725V14.2004H85.2644C85.7261 14.1237 86.034 13.8166 86.034 13.5096ZM86.1879 16.6567C86.1879 16.1962 85.88 15.8891 85.3414 15.8891H82.648V17.4243H85.3414C85.88 17.4243 86.1879 17.1173 86.1879 16.6567Z"
        fill="#DA0E17"
      />
      <path
        d="M89.957 14.7378C89.957 10.8231 91.4191 8.98087 94.1894 8.59708C95.5746 8.44356 96.0363 8.13653 96.0363 7.90625H98.2679C98.2679 9.44143 96.1902 9.97874 94.882 10.0555C92.9582 10.2858 92.1887 11.1301 91.9578 12.128C92.6504 11.2836 93.6508 10.8231 94.7281 10.8231C97.0367 10.8231 98.5757 12.8188 98.5757 15.1216C98.5757 17.4243 97.0367 19.4201 94.2664 19.4201C91.573 19.3433 89.957 17.3476 89.957 14.7378ZM96.3441 15.0448C96.3441 13.8167 95.5746 12.7421 94.2664 12.7421C92.9582 12.7421 92.1887 13.8167 92.1887 15.0448C92.1887 16.3497 92.9582 17.5011 94.2664 17.5011C95.5746 17.5011 96.3441 16.3497 96.3441 15.0448Z"
        fill="#DA0E17"
      />
      <path
        d="M100.27 20.4178C100.423 20.4946 100.731 20.5713 100.962 20.5713C101.501 20.5713 101.886 20.4178 102.116 20.034L102.424 19.3432L99.1152 11.0532H101.424L103.578 16.7334L105.733 11.0532H108.042L104.194 20.5713C103.578 22.1065 102.501 22.4903 101.116 22.567C100.885 22.567 100.27 22.4903 100.039 22.4903L100.27 20.4178Z"
        fill="#DA0E17"
      />
      <path
        d="M113.967 19.1898L112.043 16.4264L110.196 19.1898H107.811L110.735 14.968L108.041 10.9766H110.427L112.043 13.5096L113.736 10.9766H116.121L113.428 14.968L116.352 19.1898H113.967Z"
        fill="#DA0E17"
      />
      <path
        d="M22.5471 0H37.1681C39.092 0 40.631 1.53518 40.631 3.5309V17.808C40.631 20.5714 39.4767 23.3347 37.476 25.4072L25.3944 37.4583C23.4706 39.3773 20.8542 40.5286 18.1608 40.5286H3.46287C1.53905 40.5286 0 38.9935 0 36.9977V22.7206C0 19.9573 1.15429 17.194 3.15506 15.1982L15.3136 3.07035C17.2374 1.07462 19.8538 0 22.5471 0Z"
        fill="#DA0E17"
      />
      <path
        d="M19.5463 14.7373L19.0076 15.3514C18.2381 16.1957 17.2377 17.6542 17.2377 19.2661L17.3146 30.1658C17.3146 31.9313 17.0068 33.8503 16.3912 34.6179C14.8521 36.3065 14.3135 33.1594 15.2369 31.0869C15.2369 31.0869 11.3123 32.7756 12.4666 35.3854C13.1592 37.1509 15.5447 36.6136 17.3146 34.6179C18.2381 33.6967 19.2384 32.6989 20.008 31.701C20.3158 31.1637 20.9314 30.7799 20.9314 28.6307V17.9612C21.0084 17.0401 21.0853 16.1957 21.3931 15.4282L26.0872 17.1169C30.2427 18.1915 32.6282 17.8077 35.2446 15.3514C38.8614 11.8973 37.6301 8.21284 34.9368 7.75228C34.0903 7.67552 33.3977 7.9058 32.7821 8.51987C32.3204 9.05718 31.0122 10.6691 31.0122 10.6691C35.4755 9.13394 36.8606 13.893 34.3212 15.1979C32.1665 16.1957 22.9322 12.0508 20.4697 11.1297C13.8518 8.59663 11.0815 11.974 9.77327 13.8162C7.00297 17.8077 8.00336 22.2597 10.8506 22.5667C13.2361 22.797 14.3904 20.8013 16.0064 17.4239C10.9276 21.3386 9.08069 18.1147 9.92717 15.7352C10.3889 14.2768 12.3127 13.0486 15.0061 13.3557C15.083 13.3557 15.2369 13.3557 15.3139 13.4324C15.8525 13.5092 16.3912 13.5859 16.8529 13.7395C17.1607 13.893 18.1611 14.2768 19.5463 14.7373Z"
        fill="white"
      />
      <path
        d="M56.5601 34.8484L56.0215 33.39H52.8664L52.3277 34.8484H51.4043L53.9437 28.5542H54.9441L57.4836 34.8484H56.5601ZM54.4054 29.3218L53.0973 32.7759H55.7906L54.4054 29.3218Z"
        fill="black"
      />
      <path
        d="M57.9449 35.9998C58.0219 36.0766 58.2527 36.0766 58.3297 36.0766C58.6375 36.0766 58.8683 35.9998 59.0223 35.616L59.3301 34.9252L57.4062 30.2429H58.1758L59.7148 34.0041L61.2539 30.2429H62.0234L59.7148 35.7695C59.407 36.4604 58.9453 36.6907 58.4066 36.6907C58.2527 36.6907 58.0219 36.6139 57.868 36.6139L57.9449 35.9998Z"
        fill="black"
      />
      <path
        d="M63.4859 34.8484V30.857H62.1777V30.2429H65.5636V30.857H64.2555V34.8484H63.4859Z"
        fill="black"
      />
      <path
        d="M66.2559 32.5456C66.2559 31.164 67.1793 30.0894 68.5644 30.0894C69.4109 30.0894 69.8726 30.3964 70.2574 30.9337L69.7957 31.3943C69.4109 30.9337 69.0262 30.7034 68.4875 30.7034C67.4871 30.7034 66.9484 31.471 66.9484 32.4689C66.9484 33.4667 67.5641 34.2343 68.4875 34.2343C68.9492 34.2343 69.334 34.0808 69.6418 33.6203L70.1035 34.0808C69.7957 34.5414 69.257 34.9252 68.4105 34.9252C67.1793 34.9252 66.2559 33.9273 66.2559 32.5456Z"
        fill="black"
      />
      <path
        d="M71.0273 32.5456C71.0273 31.2407 71.8738 30.0894 73.3359 30.0894C74.7211 30.0894 75.6445 31.164 75.6445 32.5456C75.6445 33.9273 74.798 35.0019 73.3359 35.0019C71.8738 34.9252 71.0273 33.8505 71.0273 32.5456ZM74.7211 32.5456C74.7211 31.6245 74.1824 30.7802 73.182 30.7802C72.1816 30.7802 71.643 31.6245 71.643 32.5456C71.643 33.4667 72.1816 34.3111 73.182 34.3111C74.2594 34.3111 74.7211 33.4667 74.7211 32.5456Z"
        fill="black"
      />
      <path
        d="M77.4152 34.1577V36.614H76.7227V30.3198H77.4152V31.0106C77.723 30.5501 78.3387 30.1663 78.9543 30.1663C80.1086 30.1663 81.032 31.0874 81.032 32.6225C81.032 34.081 80.1855 35.0788 78.9543 35.0788C78.3387 34.9253 77.723 34.6183 77.4152 34.1577ZM80.2625 32.5458C80.2625 31.5479 79.7238 30.7803 78.8004 30.7803C78.2617 30.7803 77.6461 31.0874 77.4152 31.4712V33.5436C77.6461 33.9274 78.2617 34.3112 78.8004 34.3112C79.7238 34.3112 80.2625 33.5436 80.2625 32.5458Z"
        fill="black"
      />
      <path
        d="M81.877 32.5456C81.877 31.164 82.8004 30.0894 84.1855 30.0894C85.032 30.0894 85.4937 30.3964 85.8785 30.9337L85.4168 31.3943C85.109 30.9337 84.7242 30.7802 84.2625 30.7802C83.2621 30.7802 82.7234 31.5478 82.7234 32.5456C82.7234 33.5435 83.3391 34.3111 84.2625 34.3111C84.7242 34.3111 85.109 34.1576 85.4168 33.697L85.8785 34.1576C85.5707 34.6181 85.032 35.0019 84.1855 35.0019C82.8004 34.9252 81.877 33.9273 81.877 32.5456Z"
        fill="black"
      />
      <path
        d="M86.8027 34.8484V30.2429H87.5723V33.7738L89.9578 30.2429H90.6504V34.8484H89.9578V31.2408L87.4953 34.8484H86.8027Z"
        fill="black"
      />
      <path
        d="M92.1133 34.8484V30.2429H92.8059V32.1619H95.1914V30.2429H95.9609V34.8484H95.1914V32.776H92.8059V34.8484H92.1133Z"
        fill="black"
      />
      <path
        d="M100.578 30.857H98.0383V34.8484H97.3457V30.2429H100.578V30.857Z"
        fill="black"
      />
      <path
        d="M103.654 32.3155C103.654 30.0895 104.501 29.1684 106.117 28.9381C107.117 28.7846 107.425 28.6311 107.425 28.4775H108.118C108.118 29.2451 107.04 29.3986 106.348 29.5522C104.962 29.7057 104.578 30.3198 104.424 31.0873C104.732 30.55 105.347 30.0895 106.117 30.0895C107.425 30.0895 108.271 31.1641 108.271 32.469C108.271 33.7739 107.425 34.9253 105.963 34.9253C104.578 34.9253 103.654 33.8507 103.654 32.3155ZM107.502 32.5458C107.502 31.6247 106.963 30.7803 105.963 30.7803C104.962 30.7803 104.424 31.6247 104.424 32.5458C104.424 33.4669 104.962 34.3112 105.963 34.3112C106.963 34.3112 107.502 33.4669 107.502 32.5458Z"
        fill="black"
      />
      <path
        d="M109.273 35.9998C109.35 36.0766 109.581 36.0766 109.658 36.0766C109.966 36.0766 110.196 35.9998 110.35 35.616L110.658 34.9252L108.734 30.2429H109.504L111.043 34.0041L112.582 30.2429H113.352L111.043 35.7695C110.735 36.4604 110.273 36.6907 109.735 36.6907C109.581 36.6907 109.35 36.6139 109.196 36.6139L109.273 35.9998Z"
        fill="black"
      />
      <path
        d="M117.123 34.8484L115.737 32.9295L114.352 34.8484H113.506L115.276 32.4689L113.583 30.2429H114.429L115.737 32.0084L116.969 30.2429H117.815L116.122 32.4689L117.892 34.8484H117.123Z"
        fill="black"
      />
      <path
        d="M121.97 30.857H119.431V34.8484H118.738V30.2429H121.97V30.857Z"
        fill="black"
      />
      <path
        d="M125.741 34.8484V34.3111C125.356 34.7716 124.818 34.9252 124.202 34.9252C123.432 34.9252 122.586 34.3878 122.586 33.39C122.586 32.3154 123.432 31.8548 124.202 31.8548C124.818 31.8548 125.356 32.0083 125.741 32.4689V31.6245C125.741 31.0105 125.279 30.7034 124.587 30.7034C124.048 30.7034 123.586 30.8569 123.125 31.3175L122.817 30.8569C123.279 30.3196 123.894 30.0894 124.664 30.0894C125.587 30.0894 126.434 30.5499 126.434 31.6245V34.7716L125.741 34.8484ZM125.741 33.8505V33.0062C125.433 32.6224 124.971 32.4689 124.433 32.4689C123.74 32.4689 123.279 32.9294 123.279 33.4667C123.279 34.0041 123.74 34.4646 124.433 34.4646C124.971 34.3879 125.51 34.2343 125.741 33.8505Z"
        fill="black"
      />
      <path
        d="M127.357 34.3879C127.819 34.3879 128.05 33.9273 128.204 32.5457L128.512 30.2429H131.667V34.8484H130.974V30.857H129.127L128.973 32.5457C128.82 34.2344 128.281 34.9252 127.434 34.9252L127.357 34.3879Z"
        fill="black"
      />
      <path
        d="M133.974 34.8484V30.857H132.666V30.2429H136.052V30.857H134.744V34.8484H133.974Z"
        fill="black"
      />
      <path
        d="M136.744 32.5456C136.744 31.2407 137.668 30.0894 139.053 30.0894C140.438 30.0894 141.207 31.164 141.207 32.5456V32.6992H137.514C137.591 33.5435 138.206 34.2343 139.207 34.2343C139.745 34.2343 140.284 34.0808 140.669 33.697L140.977 34.1576C140.515 34.6181 139.899 34.8484 139.207 34.8484C137.668 34.9252 136.744 33.9273 136.744 32.5456ZM138.976 30.7034C137.975 30.7034 137.514 31.5478 137.437 32.2386H140.438C140.438 31.5478 140.053 30.7034 138.976 30.7034Z"
        fill="black"
      />
      <path
        d="M143.056 34.1577V36.614H142.363V30.3198H143.056V31.0106C143.364 30.5501 143.979 30.1663 144.595 30.1663C145.749 30.1663 146.673 31.0874 146.673 32.6225C146.673 34.081 145.826 35.0788 144.595 35.0788C143.979 34.9253 143.441 34.6183 143.056 34.1577ZM145.903 32.5458C145.903 31.5479 145.364 30.7803 144.441 30.7803C143.902 30.7803 143.287 31.0874 143.056 31.4712V33.5436C143.287 33.9274 143.902 34.3112 144.441 34.3112C145.364 34.3112 145.903 33.5436 145.903 32.5458Z"
        fill="black"
      />
      <path
        d="M147.826 34.8484V30.2429H148.519V33.7738L150.904 30.2429H151.597V34.8484H150.904V31.2408L148.442 34.8484H147.826Z"
        fill="black"
      />
      <path
        d="M153.059 34.8484V30.2429H153.751V33.7738L156.137 30.2429H156.906V34.8484H156.137V31.2408L153.751 34.8484H153.059Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_5809_24102">
        <rect width="200" height="40.5286" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
