import { Box } from "@mui/material";
import { memo, useRef } from "react";
function randomInteger(min, max) {
  // случайное число от min до (max+1)
  let rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
}


export const PhrasesBox = memo(({phrases}) => {
  const returnRandomPhrase = () => phrases[randomInteger(0, phrases.length - 1)];
  const phrase = useRef(returnRandomPhrase())

  return (
  <Box
    sx={{
      borderRight: "1px solid #DA0E17",
      pr: 2,
      strong: { color: "#DA0E17", fontWeight: 800 },
    }}
  >
    <span
      dangerouslySetInnerHTML={{
        __html: phrase.current,
      }}
    />
  </Box>
)});
