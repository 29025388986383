import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0278FF",
    },
  },
  typography: {
    fontFamily: "ProximaNova, sans-serif",
    allVariants: {
      color: "#000000",
      fontWeight: 300,
    },
    h1: {
      fontSize: "38px",
      fontWeight: 700,
      lineHeight: "36px",
      color: "#31343E",
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: "#9fa4a7",
        },
        root: {
          "&.Mui-checked": {
            "color": "#0278FF"
          }
        }
      },
      defaultProps: {
        sx: {
          paddingY: 0,
          pl: 0,
        },
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        sx: {
          margin: 0,
          flex: 1,
          ".MuiTypography-root": {
            color: "#0E0F0A",
          },
        },
      },
    },
    MuiFormGroup: {
      defaultProps: {
        sx: {
          display: "flex",
          justifyContent: "center",
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        sx: {
          paddingY: 0,
          pl: 0,
          color: "#0278FF",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          color: "#9fa4a7",
        },
      },
    },
  },
});
