import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Controller } from "react-hook-form";

export const SelectDefault = ({ items, control, name, required = false, label = '', sx }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl sx={sx}>
          <InputLabel sx={{ color: '#9fa4a7', span: { color: "#DA0E17" } }}>
            {label}{required && <span>*</span>}
          </InputLabel>
          <Select {...field} label={`${label} ${required ? '*' : ''}`}>
            {items.map((item) => (
              <MenuItem key={item.title + item.value} value={item.value}>{item.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
