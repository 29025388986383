import '../../App.css';
import {
  Box,
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
  Button,
} from '@mui/material';
import { Logo } from '../logo';
import { PhrasesBox } from './Phrases';
import { useForm, Controller } from 'react-hook-form';
import { SelectDefault } from '../select';
import { CopyAllOutlined, CurrencyRuble, Percent } from '@mui/icons-material';
import { DefaultCheckbox } from '../checkbox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { dicountTypes, prices, tarifs, values } from '../../const';
import { capitalizeFirstLetter, numberToWords } from '../../helpers';

export const Calculator = ({ isClientCalc = false, title = 'Калькулятор', phrases }) => {
  const { control, watch, setValue } = useForm({
    defaultValues: {
      businessType: values.businessType[0].value,
      orgType: values.orgType[0].value,
      snoType: values.snoType[0].value,
      discount: 0,
      ved: false,
      anotherProgramm: false,
      departments: false,
      departmentsQuantity: 1,
      turnover: values.turnover[0].value,
      staff: 0,
      accounts: 1,
      operations: 1,
      markup: 1,
    },
  });

  const [discountType, setDiscountType] = useState('netto');

  const [currentBase, setCurrentBase] = useState(1);

  const businessType = watch('businessType');
  const orgType = watch('orgType');
  const snoType = watch('snoType');
  const ved = watch('ved');
  const departments = watch('departments');
  const departmentsQuantity = watch('departmentsQuantity');
  const turnover = watch('turnover');
  const staff = watch('staff');
  const discount = watch('discount');
  const anotherProgramm = watch('anotherProgramm');
  const accounts = watch('accounts');
  const operations = watch('operations');
  const markup = watch('markup');

  const [tarif, setTarif] = useState('standart');

  useEffect(() => {
    setCurrentBase(values.businessType.find((item) => item.value === businessType).base);
  }, [businessType]);

  const [checkValue, setCheckValue] = useState(0);

  const checksum = useCallback(() => {
    const turnoverCoef = values.turnover.find((item) => item.value === turnover).coef;
    const staffCoef = values.staff.find(
      (item) => staff >= item.value[0] && staff <= item.value[1],
    ).coef;
    if (!turnoverCoef || !staffCoef) {
      return setCheckValue(0);
    }
    setCurrentBase(values.businessType.find((item) => item.value === businessType).base);
    let value = prices[currentBase];

    if (isClientCalc) {
      const limit = values.businessType.find((item) => item.value === businessType).limit;
      const overOperations = operations - limit;
      if (overOperations > 0) {
        value += overOperations * 15;
      }
    }
    value = value * values.orgType.find((item) => item.value === orgType).coef;
    value = value * values.snoType.find((item) => item.value === snoType).coef;
    value = value * turnoverCoef;

    if (departments) {
      value += 3000;
      value += (departmentsQuantity - 1) * 2500;
    }

    value += (accounts - 1) * 1200;

    if (ved) {
      value = value * 1.25;
    }
    if (anotherProgramm) {
      value = value * 1.5;
    }

    value = staffCoef ? value + 1000 * staff * staffCoef : 0;

    setCheckValue(value);
  }, [
    currentBase,
    isClientCalc,
    departments,
    accounts,
    ved,
    anotherProgramm,
    staff,
    turnover,
    businessType,
    operations,
    orgType,
    snoType,
    departmentsQuantity,
  ]);

  useEffect(() => {
    checksum();
  }, [checksum]);

  const [checkDiscount, setCheckDiscount] = useState(0);
  useEffect(() => {
    setCheckDiscount((checkValue * markup * (100 - discount)) / 100);
  }, [checkValue, discount, markup]);

  const result = useMemo(() => {
    if (!checkValue)
      return 'Упс... Я пока не умею считать тарифы для Среднего бизнеса.\r\nОбратитесь к руководителю за пояснениями.';
    if (!isClientCalc)
      return `Коммерческое предложение для __\r\nТариф ${tarifs[tarif].name} ${
        Number(checkValue)
          ? '— ' + (checkValue * tarifs[tarif].coef).toFixed(2) + ' рублей в месяц'
          : '-'
      }.${
        Boolean(+discount)
          ? `\r\nС учетом скидки стоимость составит ${
              Number(checkValue)
                ? '— ' + (checkDiscount * tarifs[tarif].coef).toFixed(2) + '  рублей в месяц'
                : '-'
            }.`
          : ''
      }\r\nПараметры тарифа:\r\nВид деятельности - ${
        values.businessType.find((item) => item.value === businessType).title
      }\r\nОрганизационно-правовая форма - ${
        values.orgType.find((item) => item.value === orgType).title
      }\r\nРежим налогообложения - ${
        values.snoType.find((item) => item.value === snoType).title
      }\r\nОбороты, млн. руб. - ${
        values.turnover.find((item) => item.value === turnover).title
      }\r\nЧисленность сотрудников, чел. - ${staff}\r\nВЭД - ${
        ved ? 'Да' : 'Нет'
      }\r\nОбособленные подразделения - ${
        departments ? departmentsQuantity : 'Нет'
      }\r\nКоличество расчетных счетов - ${accounts}`;
    return (
      (discountType !== 'discount'
        ? `1.    Стороны договорились, что с ______________________г.\r\nЗаказчику устанавливается тариф ${
            tarifs[tarif].name
          } стоимостью ${
            Number(checkValue) ? '— ' + (checkDiscount * tarifs[tarif].coef).toFixed(2) : '-'
          } (${capitalizeFirstLetter(
            numberToWords(parseInt(checkDiscount * tarifs[tarif].coef)),
          )} руб.${
            (checkDiscount * tarifs[tarif].coef).toFixed(2).split('.')[1]
          } коп) в месяц.\r\n1.1.    В тариф включено:\r\n`
        : `1.    Стороны договорились, что с ______________________г.\r\nЗаказчику устанавливается тариф ${
            tarifs[tarif].name
          }, с  учетом скидки в размере ${discount}%, стоимостью ${
            Number(checkValue) ? '— ' + (checkDiscount * tarifs[tarif].coef).toFixed(2) : '-'
          } (${capitalizeFirstLetter(
            numberToWords(parseInt(checkDiscount * tarifs[tarif].coef)),
          )} руб. ${
            (checkDiscount * tarifs[tarif].coef).toFixed(2).split('.')[1]
          } коп) рублей в месяц.\r\n1.1.    В тариф включено:\r\n`) +
      `•    вид деятельности - ${
        values.businessType.find((item) => item.value === businessType).title
      };\r\n` +
      `•    количество операций, шт. – ${operations};\r\n` +
      `•    организационно-правовая форма – ${
        values.orgType.find((item) => item.value === orgType).title
      };\r\n` +
      `•    режим налогообложения - ${
        values.snoType.find((item) => item.value === snoType).title
      };\r\n` +
      `•    обороты, млн. руб. - ${
        values.turnover.find((item) => item.value === turnover).title
      };\r\n` +
      `•    численность сотрудников, чел. – ${staff};\r\n` +
      `•    ВЭД – ${ved ? 'Да' : 'Нет'};\r\n` +
      `•    обособленные подразделения – ${departments ? departmentsQuantity : 'Нет'};\r\n` +
      `•    количество расчетных счетов, шт. – ${accounts}.\r\n`
    );
  }, [
    checkValue,
    isClientCalc,
    tarif,
    discountType,
    checkDiscount,
    staff,
    ved,
    departments,
    departmentsQuantity,
    accounts,
    discount,
    operations,
    businessType,
    orgType,
    snoType,
    turnover,
  ]);

  useEffect(() => {
    if (!departments) setValue('departmentsQuantity', 1);
  }, [departments, setValue]);

  useEffect(() => {
    ['discount', 'markup'].forEach((key, i) => setValue(key, i));
  }, [discountType, setValue]);

  return (
    <Box className="App" sx={{ px: 8, py: 4, minHeight: '100dvh' }}>
      <Stack maxWidth="1312px" mx="auto">
        <Stack flexDirection="row" justifyContent="space-between" mb={'36px'} flexWrap="wrap">
          <Logo />
          <PhrasesBox phrases={phrases} />
        </Stack>
        <Typography
          mb={3}
          variant="h1"
          sx={{ fontSize: '32px', lineHeight: '36px', fontWeight: 700 }}
        >
          {title}
        </Typography>
        <Stack flexDirection="row" gap={2}>
          <Box
            maxWidth="752px"
            sx={{
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(6, 1fr)',
              width: '100%',
              flex: 2,
            }}
          >
            <SelectDefault
              required
              name="businessType"
              control={control}
              items={values.businessType}
              label="Вид деятельности"
              sx={{ gridColumn: isClientCalc ? '1 / 3' : '1 / 4' }}
            />
            {isClientCalc && (
              <Controller
                control={control}
                name="operations"
                render={({ field }) => (
                  <TextField
                    sx={{ gridColumn: '3 / 5' }}
                    type="number"
                    label={
                      <Box sx={{ span: { color: '#DA0E17' } }}>
                        Количество операций <span>*</span>
                      </Box>
                    }
                    {...field}
                    onChange={(e) => {
                      if (+e.target.value >= 0) field.onChange(e.target.value.replace(/\0-9/g, ''));
                    }}
                    onBlur={(e) => {
                      if (+e.target.value < 1) field.onChange(1);
                      field.onBlur();
                    }}
                  />
                )}
              />
            )}
            <FormGroup sx={{ gridColumn: isClientCalc ? '5 / 7' : '4 / 7' }}>
              <DefaultCheckbox control={control} name="ved" label="ВЭД" />
              <DefaultCheckbox control={control} name="anotherProgramm" label="Учет не в 1С" />
            </FormGroup>
            <FormGroup sx={{ gridColumn: '1 / 4' }}>
              <DefaultCheckbox
                name="departments"
                control={control}
                label={'Обособленные подразделения'}
              />
            </FormGroup>

            <Controller
              control={control}
              name="departmentsQuantity"
              render={({ field }) => (
                <TextField
                  sx={{ gridColumn: '4 / 7' }}
                  type="number"
                  disabled={!departments}
                  label={
                    <Box sx={{ span: { color: '#DA0E17' } }}>
                      Количество ОП <span>*</span>
                    </Box>
                  }
                  {...field}
                  onChange={(e) => {
                    if (+e.target.value >= 0) field.onChange(e.target.value.replace(/\0-9/g, ''));
                  }}
                  onBlur={(e) => {
                    if (+e.target.value < 1) field.onChange(1);
                    field.onBlur();
                  }}
                />
              )}
            />
            <SelectDefault
              required
              sx={{ gridColumn: '1 / 3' }}
              name="orgType"
              control={control}
              items={values.orgType}
              label="Организационно правовая форма"
            />
            <SelectDefault
              required
              sx={{ gridColumn: '3 / 5' }}
              name="snoType"
              control={control}
              items={values.snoType}
              label="Режим налогообложения"
            />
            <Controller
              control={control}
              name="staff"
              render={({ field }) => (
                <TextField
                  type="number"
                  sx={{ gridColumn: '5 / 7' }}
                  label={
                    <Box sx={{ span: { color: '#DA0E17' } }}>
                      Численность сотрудников, чел.<span>*</span>
                    </Box>
                  }
                  {...field}
                  onChange={(e) => {
                    if (+e.target.value >= 0) field.onChange(e.target.value.replace(/\0-9/g, ''));
                  }}
                  onBlur={(e) => {
                    if (+e.target.value < 1) field.onChange(0);
                    field.onBlur();
                  }}
                />
              )}
            />
            <SelectDefault
              required
              name="turnover"
              control={control}
              items={values.turnover}
              label="Оборот, млн. ₽"
              sx={{ gridColumn: '1 / 4' }}
            />
            <Controller
              name="accounts"
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  sx={{ gridColumn: '4 / 7' }}
                  label={
                    <Box sx={{ span: { color: '#DA0E17' } }}>
                      Количество расчетных счетов<span>*</span>
                    </Box>
                  }
                  {...field}
                  onChange={(e) => {
                    if (+e.target.value >= 0) if (+e.target.value < 1) return field.onChange(1);
                    field.onChange(e.target.value.replace(/\0-9/g, ''));
                  }}
                  onBlur={(e) => {
                    if (+e.target.value < 1) field.onChange(1);
                    field.onBlur();
                  }}
                />
              )}
            />
            <Box
              sx={{
                borderTop: '1px dashed #9FA4BB',
                height: '1px',
                gridColumn: '1/-1',
              }}
            />
            {!isClientCalc ? (
              <Controller
                name="discount"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    aria-labelledby="radio-discount"
                    sx={{
                      gridColumn: '1/-1',
                      display: 'grid',
                      gap: 2,
                      gridTemplateColumns: 'repeat(2, 1fr)',
                    }}
                  >
                    {values.discount.map((item) => (
                      <FormControlLabel
                        key={item.value + 'discount'}
                        value={item.value}
                        control={<Radio />}
                        label={item.label}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            ) : (
              <Box display="flex" gridColumn="1/-1" gap="16px" flexDirection="row">
                <Controller
                  key={discountType}
                  control={control}
                  name={discountType}
                  render={({ field }) => (
                    <TextField
                      sx={{ width: 'calc(50% - 8px)' }}
                      type="number"
                      disabled={discountType === 'netto'}
                      InputProps={{
                        endAdornment:
                          discountType === 'discount' ? (
                            <Percent sx={{ color: '#9FA4BB', fontSize: '22px' }} />
                          ) : null,
                        sx: {
                          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                            {
                              display: 'none',
                            },
                          '& input[type=number]': {
                            MozAppearance: 'textfield',
                          },
                        },
                      }}
                      label={
                        discountType !== 'netto' ? (
                          <Box sx={{ span: { color: '#DA0E17' } }}>
                            {discountType === 'discount' ? 'Процент скидки' : 'Коэффициент наценки'}{' '}
                            {<span>*</span>}
                          </Box>
                        ) : null
                      }
                      {...field}
                      onChange={(e) => {
                        if (+e.target.value >= 0)
                          field.onChange(e.target.value.replace(/\0-9/g, ''));
                      }}
                    />
                  )}
                />
                <RadioGroup
                  aria-labelledby="radio-discount"
                  sx={{
                    width: 'calc(50% - 8px)',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                  }}
                  value={discountType}
                  onChange={(e) => setDiscountType(e.target.value)}
                >
                  {dicountTypes(isClientCalc).map(([key, name]) => (
                    <FormControlLabel
                      key={key + 'discount'}
                      value={key}
                      control={<Radio />}
                      label={name}
                    />
                  ))}
                </RadioGroup>
              </Box>
            )}
            <Box
              sx={{
                borderTop: '1px dashed #9FA4BB',
                height: '1px',
                gridColumn: '1/-1',
              }}
            />
            <RadioGroup
              sx={{
                gridColumn: '1/-1',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              {Object.entries(tarifs).map(([key, val]) => {
                return (
                  <Box key={'tarif' + key} display={'flex'} gap={2}>
                    <TextField
                      sx={{ flex: 1 }}
                      value={Number(checkValue) ? (val.coef * checkDiscount).toFixed(2) : '-'}
                      label={
                        <Box sx={{ span: { color: '#DA0E17' } }}>
                          {val.name} <span>*</span>
                        </Box>
                      }
                      InputProps={{
                        readOnly: true,
                        endAdornment: <CurrencyRuble sx={{ color: '#9FA4BB', fontSize: '22px' }} />,
                      }}
                    />
                    <FormControlLabel
                      sx={{ flex: 1, m: 0 }}
                      value={key}
                      control={<Radio />}
                      label={'Выбрать'}
                      checked={key === tarif}
                      onChange={(e) => setTarif(e.target.value)}
                    />
                  </Box>
                );
              })}
            </RadioGroup>
            <Box sx={{ fontSize: '12px', color: '#9FA4BB', width: '100%', gridColumn: '1 / -1' }}>
              <span style={{ color: '#DA0E17' }}>*</span> - обязательные поля
            </Box>
          </Box>
          <Box sx={{ borderLeft: '1px dashed #9FA4BB', width: '1px' }} />
          <Box
            sx={{
              flex: 0.8,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              maxWidth: '528px',
            }}
          >
            <TextField
              rows={30}
              key={result}
              label={
                <Box sx={{ backgroundColor: '#F2F3F8', px: 0.5 }}>
                  {isClientCalc ? 'Для дополнительного соглашения:' : 'Для ТКП:'}
                </Box>
              }
              multiline
              sx={{ flex: 1, display: 'flex' }}
              value={result}
              InputProps={{
                sx: { flex: 1, alignItems: 'start', fontSize: '10pt' },
              }}
            />
            <Button
              startIcon={<CopyAllOutlined />}
              sx={{ textTransform: 'none' }}
              variant="outlined"
              onClick={() => {
                if (typeof navigator.clipboard !== 'undefined')
                  navigator.clipboard.writeText(result);
                else {
                  var tempInput = document.createElement('input');
                  tempInput.style.position = 'absolute';
                  tempInput.style.left = '-9999px';

                  // Помещаем текст во временное поле
                  tempInput.value = result;
                  document.body.appendChild(tempInput);

                  // Выделяем текст во временном поле
                  tempInput.select();
                  tempInput.setSelectionRange(0, 99999); // Для мобильных устройств

                  // Копируем текст в буфер обмена
                  document.execCommand('copy');

                  // Удаляем временное поле
                  document.body.removeChild(tempInput);

                  // Сообщаем пользователю об успешном копировании
                }
                alert('Текст скопирован: ' + result);
              }}
            >
              Копировать
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
